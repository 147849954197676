import AuthenticatedEndpoint from '../helpers/api';

function submitStats(payload) {
  return AuthenticatedEndpoint.post(null, payload, {
    params: {
      op: 'finalstats',
    },
  })
    .then((res) => {
      const data = res.data;

      // Ensure the response contains the expected structure
      if (data?.success && data.result?.leaguetableurl) {
        return data.result.leaguetableurl; // Return the desired data
      }

      // Handle unexpected response structure
      throw new Error(data.error || 'Unexpected response format.');
    })
    .catch((error) => {
      // Check if the error contains a response with data
      if (error.response?.data) {
        throw error.response.data; // Re-throw the error object for further handling
      }

      // For other errors (e.g., network errors), throw a generic message
      throw new Error(error.message || 'An unknown error occurred.');
    });
}

export const statsServices = {
  submitStats,
};
