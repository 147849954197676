import { statsServices } from '../services/stats.service';
import { statsConstants } from '../constants/stats.constants';
import { tournamentsActions } from './tournaments.actions';
import { venuesActions } from './venues.actions';
import { blindsActions } from './blinds.actions';
import { playersActions } from './players.actions';

function submitStats(payload) {
  return async (dispatch) => {
    try {
      const result = await statsServices.submitStats(payload);

      // Dispatch success action with the result
      dispatch(success(result));

      // Trigger additional actions to update related data
      dispatch(tournamentsActions.getTournaments());
      dispatch(venuesActions.getVenues());
      dispatch(blindsActions.getBlinds());
      dispatch(playersActions.getPlayers());
      return result;
    } catch (error) {
      // Dispatch failure action with the error
      dispatch(failure(error));
      throw error; // Re-throw the error to propagate it
    }
  };

  function success(result) {
    return { type: statsConstants.SUBMIT_STATS_SUCCESS, result };
  }

  function failure(error) {
    return { type: statsConstants.SUBMIT_STATS_FAILURE, error };
  }
}

export const statsActions = {
  submitStats,
};
