import React from 'react';
import {
  Button,
  Form,
  Message,
  Dropdown,
  Modal,
  Radio,
  Popup,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { potActions } from '../actions/pot.actions';
import { ledgerTotals } from '../helpers/ledger';
import {
  calculatePrizeWithRounding,
  calculateTotalAllocatedCash,
  calculateTotalAllocatedPercentage,
  ordinalSuffixOf,
} from '../helpers/prizeUtils';

import { prizesActions } from '../actions/prizes.actions';

const money = 'money';
const percentage = 'percentage';

const getPrizeStructureOptions = (prizes) =>
  prizes.map(({ id, name }) => ({
    key: id,
    text: name,
    value: id,
  }));

class Prizes extends React.Component {
  constructor(props) {
    super(props);
    this.updatePrizeAllocations = this.updatePrizeAllocations.bind(this);
    this.deleteOpen = this.deleteOpen.bind(this);
    this.deleteClose = this.deleteClose.bind(this);
  }

  state = {
    deleteOpen: false,
    showModal: false,
    error: false,
    prizeAllocation: this.props.pot.prizeAllocation,
    prizeStructure: {},
    totalAllocatedCash: 0,
    totalAllocatedPercentage: 0,
    cashRounding: 1,
    selectedPrizeStructureId: this.props.pot.selectedPrizeStructureId || '', // Initialize from props or empty
    selectedPrizeStructureName: '',
  };

  componentDidMount() {
    const { pot, ledger } = this.props;
    const totalRemainingCash = ledgerTotals(ledger).totalCash;

    this.setState({
      prizeAllocation: pot.prizeAllocation,
      totalRemainingCash: totalRemainingCash,
      cashRounding: pot.cashRounding,
      selectedPrizeStructureId: pot.selectedPrizeStructureId || '', // Fallback to empty string if not defined
    });
  }

  updatePrizeAllocations(position, value, field, cashRounding) {
    const totalCashInPot = ledgerTotals(this.props.ledger).totalCash;

    let cash, percentage;
    const parsedValue = parseFloat(value) || 0;

    // Calculate cash and percentage for the updated cell
    if (field === 'cash') {
      cash = parsedValue;
      percentage = (cash / totalCashInPot) * 100;
    } else {
      percentage = parsedValue;
      cash = calculatePrizeWithRounding(
        percentage,
        totalCashInPot,
        cashRounding
      );
    }

    // Create a new prize allocation object
    const currentPrizeAllocation = { ...this.state.prizeAllocation };
    currentPrizeAllocation[position] = { cash, percentage };

    // Calculate the remaining cash
    const totalAllocatedCash = calculateTotalAllocatedCash(
      currentPrizeAllocation
    );
    const totalRemainingCash = totalCashInPot - totalAllocatedCash;

    // Update only the unselected type of field in other positions
    const otherPositions = Object.keys(currentPrizeAllocation).filter(
      (key) => key !== position
    );

    otherPositions.forEach((pos) => {
      const currentCash = currentPrizeAllocation[pos].cash || 0;

      if (field === 'cash') {
        // Update the percentage field based on the recalculated cash
        currentPrizeAllocation[pos].percentage =
          (currentCash / totalCashInPot) * 100;
      } else {
        // Update the cash field based on the recalculated percentage
        const currentPercentage = currentPrizeAllocation[pos].percentage || 0;
        currentPrizeAllocation[pos].cash = calculatePrizeWithRounding(
          currentPercentage,
          totalCashInPot,
          cashRounding
        );
      }
    });

    // Ensure total percentages align with totalCashInPot
    const totalAllocatedPercentage = calculateTotalAllocatedPercentage(
      currentPrizeAllocation
    );

    this.setState({
      prizeAllocation: currentPrizeAllocation,
      totalAllocatedCash: calculateTotalAllocatedCash(currentPrizeAllocation),
      totalAllocatedPercentage,
      error: totalRemainingCash < 0 || totalAllocatedPercentage > 100,
    });

    this.props.updatePrizeAllocations(currentPrizeAllocation, 1);
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  deleteOpen() {
    this.setState({ deleteOpen: true });
  }

  deleteClose() {
    this.setState({ deleteOpen: false });
  }

  save = () => {
    this.props.updatePrizeAllocations(
      this.state.prizeAllocation,
      this.state.cashRounding
    );
    this.closeModal();
  };

  recalculateRounding(cashRounding) {
    const newPrizeAllocation = {};
    const totalCashInPot = ledgerTotals(this.props.ledger).totalCash;

    Object.keys(this.state.prizeAllocation).forEach((key) => {
      const percentage = this.state.prizeAllocation[key].percentage;
      newPrizeAllocation[key] = {
        cash: calculatePrizeWithRounding(
          parseInt(percentage),
          totalCashInPot,
          cashRounding
        ),
        percentage: percentage,
      };
    });
    /* Object.keys(this.state.prizeAllocation).forEach((key) => {
      const percentage = this.state.prizeAllocation[key].percentage;
      const updatedCashValue = calculatePrizeWithRounding(
        parseInt(percentage),
        totalCashInPot,
        cashRounding
      );
      const updatedPercentage = Number(
        ((updatedCashValue / totalCashInPot) * 100).toFixed(2)
      ); // Keep 2 decimal places for precision

      newPrizeAllocation[key] = {
        cash: updatedCashValue,
        percentage: updatedPercentage,
      };
    });*/

    this.setState({ prizeAllocation: newPrizeAllocation });
  }

  handleDropdownChange = (_, { value }) => {
    const selectedPrizeStructure = this.props.prizes.find(
      ({ id }) => id === value
    );
    if (selectedPrizeStructure) {
      const { structure, rounding } = selectedPrizeStructure;
      const totalCashInPot = ledgerTotals(this.props.ledger).totalCash;
      const newPrizeAllocation = {};

      // Calculate prize allocation based on the selected structure and rounding
      Object.keys(structure).forEach((position) => {
        const percentage = structure[position];
        const cash = calculatePrizeWithRounding(
          percentage,
          totalCashInPot,
          rounding
        );

        newPrizeAllocation[position] = {
          cash: cash,
          percentage: percentage,
        };
      });
      this.props.updatePrizeAllocations(newPrizeAllocation, rounding);

      const totalAllocatedCash = calculateTotalAllocatedCash(
        newPrizeAllocation
      );
      const totalRemainingCash = totalCashInPot - totalAllocatedCash;
      const totalAllocatedPercentage = calculateTotalAllocatedPercentage(
        newPrizeAllocation
      );

      // Update local state with the selected prize structure, rounding, and prize allocation
      this.setState({
        prizeStructure: structure,
        cashRounding: rounding,
        prizeAllocation: newPrizeAllocation,
        selectedPrizeStructureId: value, // Store the selected structure ID
        selectedPrizeStructureName: selectedPrizeStructure.name,
        error: totalRemainingCash < 0 || totalAllocatedPercentage > 100,
      });
    }
  };

  maxRoundingValue() {
    const totalCashInPot = ledgerTotals(this.props.ledger).totalCash;

    // Calculate max rounding value as totalCashInPot / 10 rounded to the nearest integer
    const maxRounding = Math.round(totalCashInPot / 10);

    // Ensure it is at least 1 (in case totalCashInPot is very small)
    return Math.max(1, maxRounding);
  }

  generatePrizes() {
    const prizePlaces = [];

    if (this.state.prizeStructure === undefined) {
      return null;
    }

    for (let i in this.state.prizeAllocation) {
      prizePlaces.push(
        <div key={i}>
          <Form.Field className="prizes-screen-form-content">
            <div>
              <label>{ordinalSuffixOf(i)}</label>
            </div>
            <div>
              <input
                placeholder="Money"
                type="number"
                min={0}
                step="0.01" // Enable floating-point input
                disabled={this.props.pot.cashMode === false}
                className={this.props.pot.cashMode ? 'active-input' : ''}
                value={this.state.prizeAllocation[i].cash || ''}
                onChange={(event) =>
                  this.updatePrizeAllocations(
                    i,
                    event.target.value,
                    'cash',
                    this.state.cashRounding
                  )
                }
              />
            </div>
            <div>
              <input
                placeholder="Percentage"
                type="number"
                min={0}
                step="0.01" // Allow floating-point increments
                disabled={this.props.pot.cashMode === true}
                className={!this.props.pot.cashMode ? 'active-input' : ''}
                value={this.state.prizeAllocation[i].percentage || ''}
                onChange={(event) =>
                  this.updatePrizeAllocations(
                    i,
                    event.target.value,
                    'percentage',
                    this.state.cashRounding
                  )
                }
              />
            </div>
          </Form.Field>
        </div>
      );
    }

    return prizePlaces;
  }

  render() {
    const { showModal, prizeAllocation, error } = this.state;
    return (
      <>
        <Modal
          closeIcon
          trigger={
            <Button onClick={() => this.setState({ showModal: true })}>
              Prizes
            </Button>
          }
          size="fullscreen"
          centered={false}
          open={showModal}
          onClose={this.closeModal}
          className="players-modal modal-dimmer-bg-color"
        >
          <Modal.Header className="players-modal-header">Prizes</Modal.Header>
          <div className="round-buttons-container">
            <div>
              <Dropdown
                selection
                search
                className="players-table-dropdown"
                options={getPrizeStructureOptions(this.props.prizes) || []}
                onChange={this.handleDropdownChange}
                value={this.state.selectedPrizeStructureId || ''} // Use state value here
                disabled={!this.props.prizes.length}
                placeholder="Select a prize structure"
              />
            </div>
            <div className="prizes-modal-buttons">
              <Popup
                trigger={
                  <button
                    className="ui button rounds-action-buttons"
                    onClick={() => {
                      this.setState({ saveConfirmOpen: true });
                    }}
                  >
                    Save
                  </button>
                }
                on="click"
                open={this.state.saveConfirmOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                position="bottom right"
                className="save-blinds-popup"
              >
                <Popup.Header className="save-blinds-popup-header">
                  <span>Save Prizes</span>
                  <Icon
                    className="close"
                    link
                    icon="close"
                    onClick={() => this.setState({ saveConfirmOpen: false })}
                  />
                </Popup.Header>
                <Popup.Content>
                  <p>Please give this prize structure a name.</p>
                  <div className="ui input">
                    <input
                      type="text"
                      placeholder="Name..."
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  </div>
                  <div className="save-blinds-popup-button">
                    <button
                      className="ui button rounds-action-buttons"
                      onClick={async () => {
                        if (this.state.name === null) {
                          return;
                        }
                        try {
                          const newPrizes = await this.props.addPrizesStructure(
                            this.state.name,
                            this.state.cashRounding,
                            this.state.prizeAllocation
                          );

                          await new Promise((resolve) => {
                            setTimeout(() => {
                              resolve();
                            }, 500);
                          });

                          await this.props.getPrizes();
                          this.setState({
                            selectedPrizeStructureId: newPrizes?.id,
                            saveConfirmOpen: false,
                          });
                        } catch (error) {
                          console.error('Error adding prizes:', error); // Handle the error appropriately
                        }
                      }}
                    >
                      Save
                    </button>
                  </div>
                </Popup.Content>
              </Popup>
              <button
                className="ui button rounds-action-buttons"
                onClick={this.deleteOpen}
              >
                Delete
              </button>
            </div>
          </div>
          <Modal.Content className="modal-bg-content-container modal-prizes-screen-content">
            <Segment>
              <Form>
                <Form.Group widths="equal">
                  <div>
                    <h3 className="h3-prizes-title">
                      Used: {calculateTotalAllocatedCash(prizeAllocation)} of{' '}
                      {ledgerTotals(this.props.ledger).totalCash} of cash.
                    </h3>{' '}
                    <h3 className="h3-prizes-title">
                      Used:{' '}
                      {Math.round(
                        calculateTotalAllocatedPercentage(prizeAllocation) * 100
                      ) / 100}{' '}
                      of 100%
                    </h3>
                    <Form.Group widths="equal">
                      {this.generatePrizes(0)}
                    </Form.Group>
                    {error && (
                      <Message negative>
                        <Message.Header>Error</Message.Header>
                        <p>
                          You have allocated more prize money than is in the
                          prize pool.
                        </p>
                      </Message>
                    )}
                  </div>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field>
                    <Radio
                      label="Cash"
                      name="radioGroup"
                      value={money}
                      checked={this.props.pot.cashMode === true}
                      onChange={() =>
                        this.props.updatePrizeAllocationType(true)
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Percentage"
                      name="radioGroup"
                      value={percentage}
                      checked={this.props.pot.cashMode === false}
                      onChange={() =>
                        this.props.updatePrizeAllocationType(false)
                      }
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field>
                    <label className="rounding-field">Rounding</label>
                    <input
                      className="rounding-field-input"
                      placeholder="1"
                      type="number"
                      value={this.state.cashRounding}
                      min={1}
                      max={this.maxRoundingValue()}
                      step={1}
                      onChange={(e) => {
                        const newRounding = e.target.value
                          ? parseInt(e.target.value)
                          : 1; // Default to 0 if input is empty
                        this.setState({ cashRounding: newRounding });
                        this.recalculateRounding(newRounding);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.deleteOpen}
          onClose={this.deleteClose}
          className=" delete-confirm-popup "
          style={{
            width: '400px',
            maxWidth: '80%',
            marginTop: '20px',
          }}
          centered
        >
          <Modal.Content
            className=""
            style={{ padding: '20px', textAlign: 'center' }}
          >
            {`Are you sure you want to delete ${this.state.selectedPrizeStructureName} prize structure?`}
          </Modal.Content>

          <Modal.Actions
            className=""
            style={{ padding: '10px', textAlign: 'center' }}
          >
            <Button
              className="rounds-action-buttons"
              style={{ margin: '10px' }}
              content="Yes"
              positive
              onClick={async () => {
                try {
                  await this.props.deletePrizes(
                    this.state.selectedPrizeStructureId
                  );
                  await this.props.getPrizes();
                  this.deleteClose();
                } catch (error) {
                  console.error('Error deleting prizes:', error);
                }
              }}
            />
            <Button
              className="rounds-action-buttons"
              onClick={this.deleteClose}
              style={{ margin: '10px' }}
            >
              No
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ game, ledger }) => ({
  pot: game.tournament.pot,
  ledger: ledger,
  prizes: game.prizes,
});

export default connect(mapStateToProps, {
  updatePrizeAllocationType: potActions.updatePrizeAllocationType,
  updatePrizeAllocations: potActions.updatePrizeAllocations,
  getPrizes: prizesActions.getPrizes,
  addPrizesStructure: prizesActions.addPrizesStructure,
  deletePrizes: prizesActions.deletePrizes,
})(Prizes);
